import React from "react";
import { Link } from "gatsby";

const footer = (props) => {
  return (
    <div className="footer">
      <svg
        className="wave"
        width="100%"
        height="203"
        viewBox="0 0 1440 203"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 203L40 188.884C80 174.096 160 145.192 240 140.487C320 135.781 400 154.603 480 145.192C560 135.781 640 96.7947 720 96.7947C800 96.7947 880 135.781 960 149.897C1040 164.685 1120 154.603 1200 130.404C1280 106.205 1360 67.8907 1400 48.3974L1440 28.904V2.86102e-06H1400C1360 2.86102e-06 1280 2.86102e-06 1200 2.86102e-06C1120 2.86102e-06 1040 2.86102e-06 960 2.86102e-06C880 2.86102e-06 800 2.86102e-06 720 2.86102e-06C640 2.86102e-06 560 2.86102e-06 480 2.86102e-06C400 2.86102e-06 320 2.86102e-06 240 2.86102e-06C160 2.86102e-06 80 2.86102e-06 40 2.86102e-06H0V203Z"
          fill="#D9D7FA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 175.622L40 163.41C80 150.616 160 125.61 240 121.54C320 117.469 400 133.752 480 125.61C560 117.469 640 83.7401 720 83.7401C800 83.7401 880 117.469 960 129.681C1040 142.475 1120 133.752 1200 112.817C1280 91.8816 1360 58.7344 1400 41.8701L1440 25.0057V-6.31809e-06H1400C1360 -6.31809e-06 1280 -6.31809e-06 1200 -6.31809e-06C1120 -6.31809e-06 1040 -6.31809e-06 960 -6.31809e-06C880 -6.31809e-06 800 -6.31809e-06 720 -6.31809e-06C640 -6.31809e-06 560 -6.31809e-06 480 -6.31809e-06C400 -6.31809e-06 320 -6.31809e-06 240 -6.31809e-06C160 -6.31809e-06 80 -6.31809e-06 40 -6.31809e-06H0V175.622Z"
          fill="white"
        />
      </svg>
      <div className="w-logo-bg w-logo-bg--opacity"></div>
      <div className="container py-5">
        <div className="row text-center text-md-left justify-content-between">
          <div className="col-md-5 align-self-center mb-5">
            <h3
              className="text-white font-weight-bold mb-4"
              style={{ fontSize: "xx-large" }}
            >
              Let's build your website.
            </h3>
            <Link className="btn btn-primary btn-orage shadow-dark" to="/">
              Get a Price
            </Link>
          </div>
          <div className="col-md-3 col-lg-2 mb-5">
            <h6 className="text-uppercase text-orange pb-2">Quick links</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/">FAQs</Link>
              </li>
              <li>
                <Link to="/">Our Thesis</Link>
              </li>
              <li>
                <Link to="/">Pricing</Link>
              </li>
              <li>
                <Link to="/">Blog</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-2 mb-5">
            <h6 className="text-uppercase text-orange pb-2">Company</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/">About Us</Link>
              </li>
              <li>
                <Link to="/">Contact Us</Link>
              </li>
              <li>
                <Link to="/">Service Agreement</Link>
              </li>
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Terms of Service</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center copyright">
        <div className="container">
          <span className="d-block d-md-inline-block">
            © {new Date().getFullYear()} WebriQ. All rights reserved.
          </span>
          <span className="ml-md-1 d-block d-md-inline-block">
            Designed and powered by 
            <a
              className="mx-0"
              href="https://www.webriq.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              WebriQ
            </a>
            .
          </span>
        </div>
      </div>
    </div>
  );
};

export default footer;
