import React from "react";
import { Link } from "gatsby";
import SideBarMenu from "@components/sidebar/sidebar";
import Sticky from "react-sticky-el";

import Logo from "@images/site/webriq-logo.png";

const Header = () => (
  <header>
    <Sticky
      className="sticky-wrapper"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: "unset", zIndex: "10" }}
    >
      <div className="header">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/">
              <img className="header-logo" src={Logo} alt="LeadRoll" />
            </Link>
            <div>
              <div className="d-none d-lg-inline-block">
                <Link to="/" className="menu-link">
                  FAQs
                </Link>
                <Link to="/" className="menu-link">
                  Blog
                </Link>
                <Link to="/" className="menu-link">
                  About Us
                </Link>
                <Link to="/" className="menu-link">
                  Contact Us
                </Link>
              </div>
              <Link to="/" className="btn btn-primary header-cta">
                Get a Price
              </Link>
              <div className="d-inline-block d-lg-none">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
);

export default Header;
